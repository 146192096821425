<template>
  <svg
    fill="none"
    height="60"
    viewBox="0 0 60 60"
    width="60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="v-gift-colored-secondary-variant"
      d="M32.5935 57.8739L5.7897 53.9598L3.76074 20.5219L32.5935 24.432L35.0499 34.3891L32.5935 57.8739Z"
    />
    <path
      class="v-gift-colored-secondary-variant"
      d="M32.5935 57.8739L4.35984 53.7521L3.76074 20.5219L32.5935 24.432L35.0499 34.3891L32.5935 57.8739Z"
    />
    <path
      class="v-gift-colored-secondary"
      d="M32.5938 57.8739L55.096 53.4845L55.4475 20.5219L32.5938 24.432V57.8739Z"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M32.7303 24.412V24.432L32.6544 24.424L32.5945 24.432L3.89746 20.5378V21.9118L3.92941 26.3252L32.5945 30.2153L32.7303 30.2313V30.2513L55.3484 26.3771L55.4482 20.5219L32.7303 24.412Z"
        fill="#EADFC2"
      />
    </g>
    <path
      class="v-gift-colored-secondary-variant"
      d="M2.61035 15.6571V24.0925L32.7292 28.1823L33.4961 23.8848L32.7292 19.747L2.61035 15.6571Z"
    />
    <path
      class="v-gift-colored-secondary"
      d="M32.7285 19.747V28.1823L56.5968 24.0925V15.6571L32.7285 19.747Z"
    />
    <path
      class="v-gift-colored-secondary"
      d="M2.61035 15.6571L26.9978 12.0945L56.5975 15.6571L32.7292 19.747L2.61035 15.6571Z"
    />
    <path
      class="v-gift-colored-primary"
      d="M21.3052 56.3283L20.734 18.1176L44.6622 14.2194L37.7086 13.3846L14.5273 17.2748L14.9028 55.3937L21.3052 56.3283Z"
    />
    <path
      class="v-gift-colored-primary-variant"
      d="M20.734 18.1176L44.6622 14.2194L37.7086 13.3846L14.5273 17.2748L20.734 18.1176Z"
    />
    <path
      class="v-gift-colored-primary"
      d="M42.1462 56.0326L42.3578 18.1174L18.4297 14.2193L24.5964 13.696L48.5606 17.2747V54.8145L42.1462 56.0326Z"
    />
    <path
      class="v-gift-colored-primary-variant"
      d="M42.3574 18.1175L13.4287 14.0755L20.3543 13.0651L48.5601 17.2748L42.3574 18.1175Z"
    />
    <path
      class="v-gift-colored-primary-darkest"
      d="M43.3731 5.85967C42.9378 5.31249 41.9912 5.03291 41.3641 5.05288C41.1404 5.06087 40.2737 5.51219 39.1794 6.41484C39.3312 7.32548 39.0955 8.76332 37.458 10.341C36.747 11.0239 35.6806 11.7788 34.5423 12.5017C34.071 13.5442 33.6796 14.6904 33.416 15.9526C33.416 15.9526 39.6467 12.9011 41.9912 10.6445C44.3357 8.38788 43.8084 6.40685 43.3771 5.86366L43.3731 5.85967Z"
    />
    <path
      class="v-gift-colored-primary"
      d="M30.3604 15.5173C30.3604 15.5173 31.6225 4.65355 34.8017 3.77087C37.1861 3.78685 38.7238 4.79734 41.3679 5.05295C38.1167 6.32704 34.5501 11.7509 33.4158 15.9526C30.9754 16.4079 30.3643 15.5213 30.3643 15.5213L30.3604 15.5173Z"
    />
    <path
      class="v-gift-colored-primary-darkest"
      d="M30.7602 16.3203C30.7602 16.3203 26.922 4.16646 18.1191 4.75758C19.8046 5.36067 27.4292 16.0527 27.4292 16.0527L30.7562 16.3203H30.7602Z"
    />
    <path
      class="v-gift-colored-primary-darken"
      d="M18.1194 4.75805C18.1194 4.75805 12.7195 5.69664 16.6576 11.6118C19.2377 15.49 30.7605 16.3207 30.7605 16.3207C30.7605 16.3207 29.2108 16.161 27.2497 15.8734C24.6576 15.498 23.8468 13.7765 22.7365 12.7141C18.4389 8.60429 20.1444 4.44252 23.5952 6.30373C21.7939 4.51841 18.1194 4.75405 18.1194 4.75405V4.75805Z"
    />
    <path
      class="v-gift-colored-primary-darken"
      d="M31.3666 12.2343C31.3666 12.2343 33.088 6.38305 31.6541 4.05055C30.2203 1.71804 24.8603 2.10946 24.7285 6.02359C29.0341 9.63818 31.3666 12.2343 31.3666 12.2343Z"
    />
    <path
      class="v-gift-colored-primary-darken"
      d="M31.7938 16.0805C31.7938 16.0805 30.5636 8.78348 29.5691 7.20584C28.5746 5.6282 26.0025 4.7615 22.7354 6.21533C27.0129 7.22182 29.0339 15.0621 29.1657 16.0805C30.4797 16.6557 31.7938 16.0805 31.7938 16.0805Z"
    />
    <path
      class="v-gift-colored-primary"
      d="M27.9159 5.85191C27.9159 5.85191 24.6527 5.29674 24.6927 7.89285C24.7566 12.0906 31.127 16.2883 31.127 16.2883C31.127 16.2883 28.3272 16.444 26.9293 16.0806C25.5314 15.7171 20.1914 12.7256 19.9518 9.92581C19.5324 5.04911 25.1879 4.15445 27.9159 5.85191Z"
    />
    <path
      class="v-gift-colored-primary-darken"
      d="M35.6393 6.03956C35.6393 6.03956 33.5145 6.7425 32.3842 9.92573C31.2538 13.109 31.5854 16.1524 31.5854 16.1524C31.5854 16.1524 33.2469 15.785 33.7341 15.062C33.854 12.7056 36.2583 7.30167 39.4815 6.6786C37.7122 5.85982 35.6353 6.03956 35.6353 6.03956H35.6393Z"
    />
    <path
      class="v-gift-colored-primary"
      d="M31.0558 16.2402C31.0558 16.2402 39.9624 16.5238 42.1951 10.5008C42.882 7.88476 40.5695 4.06648 35.6369 6.03953C38.3808 7.40548 38.3009 13.069 31.0518 16.2442L31.0558 16.2402Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@use '~/assets/variables';

.v-gift-colored-primary {
  fill: variables.$PrimaryBackgroundColor;
}

.v-gift-colored-primary-variant {
  fill: variables.$PrimaryBackgroundColorLightest;
}

.v-gift-colored-primary-darken {
  fill: variables.$PrimaryBackgroundColorDarken;
}

.v-gift-colored-primary-darkest {
  fill: variables.$PrimaryBackgroundColorDarkest;
}

.v-gift-colored-secondary {
  fill: variables.$SecondaryBackgroundColor;
}

.v-gift-colored-secondary-variant {
  fill: variables.$SecondaryBackgroundColorLightest;
}

.v-gift-colored-secondary-lighten {
  fill: variables.$SecondaryBackgroundColorLighten;
}
</style>
